import React from 'react';
import './About.css';
import aboutUs from "../images/aboutUs.jpg";


function About() {
    return(
        <div className="about-main-section">
            <div className="about-section">
                <h1>Studio Fotograficzne Piątkowscy</h1>
                <h2>
                    Od 1997 roku, łączymy pasję do fotografii z&nbsp;doświadczeniem, oferując profesjonalne usługi fotograficzne.
                    Specjalizujemy się w&nbsp;uwiecznianiu wyjątkowych wydarzeń, w&nbsp;tym zdjęcia okolicznościowe, sesje zdjęciowe oraz obsługę eventów różnego rodzaju.
                    Posiadamy profesjonalny sprzęt, a&nbsp;naszą ofertę wyróżnia oryginalność i&nbsp;estetyka naszych prac.
                    Dysponujemy profesjonalne laboratorium fotograficzne, dzięki któremu oferujemy wysokiej jakości druk zdjęć.
                    Zasięg naszych działań nie ogranicza się do Gliwic i&nbsp;okolic.
                    Jesteśmy otwarci na potrzeby naszych klientów i&nbsp;gotów działać tam, gdzie nasze usługi są potrzebne.
                    {/*, włączając naszego fotografa stacjonującego w Londynie.*/}

                </h2>
            </div>
            <div className="side-section">
                <img src={aboutUs} alt="aboutUs"/>
            </div>
        </div>

    );

}

export default About;