import './App.css';
import TopMenu from "./components/navigation/TopMenu";
import Contact from "./components/contact/Contact";
import {useEffect, useRef, useState} from "react";
import About from "./components/about/About";
import Home from "./components/home/Home";
import FotoOnline from "./components/fotoOnline/FotoOnline";
import PriceList from "./components/priceList/PriceList";
import Offer from "./components/offer/Offer";
// import Portfolio from "./components/portfolio/Portfolio";



function App() {
    // Create a ref for each section
    const homeRef = useRef(null);
    const aboutRef = useRef(null);
    // const portfolioRef = useRef(null);
    const offerRef = useRef(null);
    const priceListRef = useRef(null);
    const fotoOnlineRef = useRef(null);
    const contactRef = useRef(null);
    const [activeSection, setActiveSection] = useState('home');
    // Function to scroll to a ref
    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    // Handle scroll to update active section state
    useEffect(() => {
        const handleScroll = () => {
            const offsets = {
                home: homeRef.current.offsetTop,
                about: aboutRef.current.offsetTop,
                // portfolio: portfolioRef.current.offsetTop,
                offer: offerRef.current.offsetTop,
                priceList: priceListRef.current.offsetTop,
                fotoOnline: fotoOnlineRef.current.offsetTop,
                contact: contactRef.current.offsetTop
            };

            const currentOffset = window.pageYOffset + 93; // 93px is for the navbar height
            let currentSection = activeSection;

            // Determine which section is currently visible
            Object.keys(offsets).forEach(key => {
                if (currentOffset >= offsets[key]) {
                    currentSection = key;
                }
            });

            setActiveSection(currentSection);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [activeSection]); // The empty dependency array ensures this effect is only run on mount and unmount



    return (
      <div className="App">
          <TopMenu scrollToRef={scrollToRef} refs={{ homeRef, aboutRef, /*portfolioRef,*/ offerRef, priceListRef, fotoOnlineRef, contactRef }}></TopMenu>
          <div className="App-content">
              <section ref={homeRef} id="home" className="snap-section">
                  <p>
                      <Home/>
                  </p>
              </section>
              <section ref={aboutRef} id="about" className="snap-section">
                  <p>
                      <About/>
                  </p>
              </section>
              {/*<section ref={portfolioRef} id="portfolio" className="snap-section">*/}
              {/*    <p>*/}
              {/*        <Portfolio/>*/}
              {/*    </p>*/}
              {/*</section>*/}
              <section ref={offerRef} id="offer" className="snap-section">
                  <p>
                      <Offer/>
                  </p>
              </section>
              <section ref={priceListRef} id="priceList" className="snap-section">
                  <p>
                      <PriceList scrollToRef={scrollToRef} fotoOnlineRef={fotoOnlineRef} />

                  </p>
              </section>
              <section ref={fotoOnlineRef} id="fotoOnline" className="snap-section">
                  <p>
                      <FotoOnline/>
                  </p>
              </section>
              <section ref={contactRef} id="contact" className="snap-section">
                 <p>
                  <Contact/>
                 </p>
              </section>

          </div>


      </div>
  );
}

export default App;
