import React from 'react';
import './Contact.css';
import { HiOutlineClock, HiOutlineEnvelope, HiOutlineMapPin, HiOutlinePhone} from "react-icons/hi2";


function Contact() {
    return (
        <div className="contact-section">
            <div className="contact-info">
                {/* Twoje dane kontaktowe tutaj */}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <h1><a className="icon-class"><HiOutlineMapPin /></a> Usługi Fotograficzne Gliwice Piątkowska Irena</h1>
                <h2>Jedności 12, 44-119 Gliwice</h2>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <h1><a className="icon-class"><HiOutlinePhone /></a> Telefon</h1>
                <h2>505 767 780</h2>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <h1><a className="icon-class"><HiOutlineEnvelope /></a> E-mail</h1>
                <h2>irenapiatkowska.fotograf@gmail.com</h2>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <h1><a className="icon-class"><HiOutlineClock /></a> Godziny otwarcia</h1>
                <h2>Pn-pt: 10:00 - 18:00</h2>
                <h2>Sob: 9:00 - 13:00</h2>


                {/* I tak dalej */}
            </div>
            <div className="contact-map">
                {/* Tutaj możesz umieścić mapę, np. z Google Maps */}
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20397.320936233285!2d18.702070240202413!3d50.279511188466856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47113178a6a18401%3A0x240f24fd6313a6c6!2sPhotographic%20Services%20in%20Gliwice%20Pi%C4%85tkowska%20Irena!5e0!3m2!1sen!2spl!4v1708201592151!5m2!1sen!2spl"
                    width="90%"
                    height="70%"
                    style={{border: 0}}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </div>
        </div>
    );
}

export default Contact;


