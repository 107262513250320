import React from 'react';
import './FotoOnline.css'
import fotoOnlineBanner from "../images/fotoOnlineBanner.jpg";
import fotoSideLeft from "../images/offerSideLeft.jpg";
import fotoSideRight from "../images/offerSideRight.jpg";
import { HiOutlineShoppingBag } from "react-icons/hi2";



function FotoOnline(){
    return (
        <div className="foto-online">
            <div className="foto-online-left">
                <img src={fotoSideLeft} alt="fotoSideLeft"/>
            </div>
            <div className="foto-online-center">
                <h1>Zapraszamy do naszego partnera Mobile Photo Kiosk celem wykonania własnych zdjęć i&nbsp;gadżetów</h1>
                <h2><a href="https://mobilephotokiosk.app/shop/1949-1/c/0" target="_blank" rel="noreferrer"><HiOutlineShoppingBag/>Zrób zdjęcia online</a></h2>
                <a href="https://mobilephotokiosk.app/shop/1949-1/c/0" target="_blank" rel="noreferrer"><img src={fotoOnlineBanner} alt="fotoOnlineBanner"/></a>
            </div>

            <div className="foto-online-right">
                <img src={fotoSideRight} alt="fotoSideRight"/>
            </div>

        </div>
    );
}
;

export default FotoOnline;
