/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import './Offer.css';

import photo1 from "../images/portfolioGallery/photo1.jpg";
import photo2 from "../images/portfolioGallery/photo2.jpg";
import photo3 from "../images/portfolioGallery/photo3.jpg";
import photo4 from "../images/portfolioGallery/photo4.jpg";
import photo5 from "../images/portfolioGallery/photo5.jpg";
import photo6 from "../images/portfolioGallery/photo6.jpg";

// Each image object now contains text for the enlarged view
const photos = [
  { src: photo1, id: 1, thumbnailText: 'Sesje Ślubne', enlargedText: 
  <div>
    <h1>Oferta - Sesje Ślubne </h1>
    <h3>Oferujemy różne pakiety ślubne w cenie od 1000 zł do 4500 zł</h3>
    <h5>
    - Pełna oprawę fotograficzna (do 2 tys.zdjęć) <br></br>
    - Zdjęcia w albumach lub Fotoksiążka do wyboru  <br></br>
    - Video + teledyski/płyty lub dyski, pendrive/ <br></br>
    - Mini Albumiki dla Rodziców gratis
    </h5>
    <h4>Możliwość dostarczenia wybranych kilku ujęć w dniu ślubu dla Gości + zdjęcie w Formacie 25x38 w gratisie dla Pary Młodej</h4>
    <h5>Oferujemy dodatkowe usługi dzięki którym Wasze imprezy pozostaną w&nbsp;pamięci uczestników na długo!</h5>
  </div>
  },
  { src: photo2, id: 2, thumbnailText: 'Sesje Dziecięce', enlargedText: 
  <div>
    <h1>Oferta - Sesje Dziecięce </h1>
    <h2>Wycena indywidualna</h2>
    
  </div> },
  { src: photo3, id: 3, thumbnailText: 'Sesje Ciążowe', enlargedText: 
    <div>
    <h1>Oferta - Sesje Ciążowe </h1>
    <h2>Wycena indywidualna</h2>
    
  </div>
   },
  { src: photo4, id: 4, thumbnailText: 'Reprodukcje', enlargedText: 
    <div>
    <h1>Oferta - Reprodukcje </h1>
    <h2>Wycena indywidualna zależna od stanu zdjęć</h2>
    
  </div>
   },
  { src: photo5, id: 5, thumbnailText: 'Fotobudka', enlargedText: 
    <div>
    <h1>Oferta - Fotobudka </h1>
    <h3>Oferujemy 3 pakiety Fotobudki:</h3>
    <h5>
      - Pakiet Mini 1 Godzina - Cena 300 zł (Dojazd gratis do 30km od Gliwic)<br></br>
      - Pakiet Classic 2 Godziny - 550 zł (Dojazd gratis do 30km od Gliwic)<br></br>
      - Pakiet Max 5 Godzin - 1200 zł <br></br>
      (Dojazd gratis na terenie woj. Śląskiego oraz gratis pendrive ze zdjęciami z fotobudki)
    </h5>
    <h3>Każdy pakiet zawiera:</h3>
    <h5>
    - Album do wpisywania dedykacji <br></br>
    - Nieograniczoną ilość zdjęć <br></br>
    - Gadżety, które pobudzą kreatywność <br></br>
    - Zdjęcia Kolorowe lub Czarno-Białe <br></br>
    - Personalizacja wydruków <br></br>
    - Opieka asystentów <br></br>
    - Galeria internetowa online <br></br>
    - Możliwość bezpośredniej publikacji zdjęć z fotobudki na portale społecznościowe <br></br>
    - Tła do wyboru lub opcja Green Screen <br></br>
    - Po zakończeniu imprezy możliwość wysyłki zdjęć na e-mail
    </h5>
    
  </div>
   },
  { src: photo6, id: 6, thumbnailText: 'Pierwsza Komunia Święta', enlargedText: 
    <div>
      <h1>Oferta - I Komunia Święta</h1>
      <h3>150 zł + Video</h3>
      <h4>W tym pendrive z wszystkimi zdjęciami z uroczystości I&nbsp;Komunii +&nbsp;mini album z trzema zdjęciami w&nbsp;formacie 15x21</h4>
      <h5>
      - Przyjęcie I Komunii <br></br>
      - Grupowe, portret  <br></br>
      - Plus film</h5>
      <h3>120 zł tylko Foto lub Video</h3>
      <h4> W cenie pendrive plus mini album z&nbsp;trzema zdjęciami</h4>
    </div>
  }
];

function Offer() {
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const handleClick = (photo) => {
    setSelectedPhoto(photo);
  };

  const closeEnlargedPhoto = () => {
    setSelectedPhoto(null);
  };

  return (
    <div className='offer-main'>
      <div className="offer">
        {photos.map((photo) => (
          <div className="tile" key={photo.id} onClick={() => handleClick(photo)}>
            <div className='photo'><img src={photo.src} alt={photo.thumbnailText} /></div>
            <div className="thumbnail-text">{photo.thumbnailText}</div>
          </div>
        ))}
      </div>
      
      {selectedPhoto && (
        <div className="enlarged-photo-overlay" onClick={closeEnlargedPhoto}>
          <div className="enlarged-photo-content">
          <img src={selectedPhoto.src} alt={`Selected Photo ${selectedPhoto.id}`} className="darker-image" />
            <div className="overlay-text">{selectedPhoto.enlargedText}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Offer;