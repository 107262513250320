import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import './Home.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';

import photo1 from "../images/homeGallery/ph1.jpg";
import photo2 from "../images/homeGallery/ph2.jpg";
import photo3 from "../images/homeGallery/ph3.jpg";
import photo4 from "../images/homeGallery/ph4.jpg";
import photo5 from "../images/homeGallery/ph5.jpg";
import photo6 from "../images/homeGallery/ph6.jpg";
import photo7 from "../images/homeGallery/ph7.jpg";

function Home(){
    return (
        <div className="gallery">
            <Swiper
                spaceBetween={30}
                effect={'fade'}
                loop={true}
                navigation={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay, EffectFade, Navigation, Pagination]}
                className="swiper"
            >
                <SwiperSlide>

                        <img className="homePhoto" src={photo1} alt="Sesje Ślubne"/>
                        <div className="photoHeader">Foto Piątkowscy</div>
                        <div className="photoDescription">Sesje Ślubne</div>

                </SwiperSlide>
                <SwiperSlide>
                    <img className="homePhoto" src={photo3} alt="Sesje Komunijne"/>
                    <div className="photoHeader">Foto Piątkowscy</div>
                    <div className="photoDescription">Sesje Komunijne</div>
                </SwiperSlide>

                <SwiperSlide>
                    <img className="homePhoto" src={photo5} alt="Sesje Dziecięce"/>
                    <div className="photoHeader">Foto Piątkowscy</div>
                    <div className="photoDescription">Sesje Dziecięce</div>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="homePhoto" src={photo7} alt="Fotobudka"/>
                    <div className="photoHeader">Foto Piątkowscy</div>
                    <div className="photoDescription">Foto Budka</div>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="homePhoto" src={photo6} alt="Sesje Rodzinne"/>
                    <div className="photoHeader">Foto Piątkowscy</div>
                    <div className="photoDescription">Sesje Rodzinne</div>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="homePhoto" src={photo2} alt="Zdjęcia z Drona"/>
                    <div className="photoHeader">Foto Piątkowscy</div>
                    <div className="photoDescription">Zdjęcia z Drona</div>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="homePhoto" src={photo4} alt="Gadżety"/>
                    <div className="photoHeader">Foto Piątkowscy</div>
                    <div className="photoDescription">Gadżety</div>
                </SwiperSlide>
                
                
            </Swiper>
        </div>
    );
}


export default Home;
