import React, {useState} from 'react';

import './PriceList.css';



const priceListContent = {
    documents: <div className="pricing-content">
        <h1>Zdjęcia do Dowodu Osobistego/Prawa Jazdy/Paszportu/Legitymacji (6szt.)</h1>
        <h2>50 zł</h2>
        <h1>Zdjęcia Dyplomowe (4 szt.)</h1>
        <h2>70 zł</h2>
        <h1>Zdjęcia do Dokumentów Niestandardowych</h1>
        <h2>Od 50 zł</h2>
        <h1>Zdjęcia wykonujemy również w&nbsp;domu klienta</h1>
    </div>,

    developing: <div className="pricing-content">
        <h1>Zdjęcia 9x13</h1>
        <h2>0.90 zł szt.</h2>
        <h1>Zdjęcia 10x15</h1>
        <h2>1.00 zł szt.</h2>
        <h1>Zdjęcia 13x18</h1>
        <h2>1.80 zł szt.</h2>
        <h1>Zdjęcia 15x21</h1>
        <h2>2.00 zł szt.</h2>
        <h1>Zdjęcia 20x30</h1>
        <h2>10.00 zł szt.</h2>

        <h1>Przy zakupie większej ilości zdjęć cena za 1szt. jest mniejsza</h1>
    </div>,

    bigformatprint: <div className="pricing-content">
        <h1>Wydruki, papier Satyna - 30x40</h1>
        <h2>60.00 zł</h2>
        <h1>Wydruki, papier Satyna - 40x50</h1>
        <h2>70.00 zł</h2>
        <h1>Wydruki, papier Satyna - 50x70</h1>
        <h2>80.00 zł</h2>
        <h1>Wydruki, papier Satyna - 60x80</h1>
        <h2>90.00 zł</h2>
        <h1>Wydruki, papier Satyna - 60x90</h1>
        <h2>120.00 zł</h2>
        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
        <h1></h1>
        <h1>Wydruki, papier Płótno - 20x30</h1>
        <h2>150.00 zł</h2>
        <h1>Wydruki, papier Płótno - 30x40</h1>
        <h2>170.00 zł</h2>
        <h1>Wydruki, papier Płótno - 40x50</h1>
        <h2>250.00 zł</h2>
        <h1>Wydruki, papier Płótno - 60x80</h1>
        <h2>350.00 zł</h2>


    </div>,

    others: <div className="pricing-content">
        <h1>Wywoływanie Filmu</h1>
        <h2>16.00 zł</h2>
        <h1>Skanowanie Slajdów od 1 szt.</h1>
        <h2>1.50 zł</h2>
        <h1>Fotogadżety</h1>
        <h2>Od 20.00 zł</h2>
        <h1>Kopiowanie Kaset VHS i innych</h1>
        <h2>Od 20.00 zł za 1 godz. materiału</h2>
        <h1>Dron - Filmowanie/Zdjęcia</h1>
        <h2>Wycena indywidualna od 300 zł</h2>
        <h1>Fotobudka</h1>
        <h2>Od 300 zł</h2>
        <h1>I Komunia Święta</h1>
        <h2>Od 120 zł</h2>
        <h1>Sesje Ślubne</h1>
        <h2>Wycena indywidualna</h2>
        


    </div>,
};

function PriceList({ scrollToRef, fotoOnlineRef }) {
    const [activeSection, setActiveSection] = useState('documents');

    const isActive = (section) => activeSection === section;



    return (
        <div className="pricing-container">
            <header>Cennik</header>
            <div className="content-container">
                <div className="pricing-menu">
                    <ul>
                        <li
                            className={isActive('documents') ? 'active' : ''}
                            onClick={() => setActiveSection('documents')}
                        >
                            Zdjęcia do dokumentów
                        </li>
                        <li
                            className={isActive('developing') ? 'active' : ''}
                            onClick={() => setActiveSection('developing')}
                        >
                            Wywoływanie zdjęć
                        </li>
                        <li
                            className={isActive('bigformatprint') ? 'active' : ''}
                            onClick={() => setActiveSection('bigformatprint')}
                        >
                            Wydruki Wielkoformatowe
                        </li>
                        <li
                            className={isActive('others') ? 'active' : ''}
                            onClick={() => setActiveSection('others')}
                        >
                            Pozostałe
                        </li>
                        <li onClick={() => scrollToRef(fotoOnlineRef)}>
                            Zdjęcia i gadżety Online
                        </li>
                        {/* Możesz dodać więcej pozycji menu */}
                    </ul>
                </div>
                {priceListContent[activeSection]}
            </div>

        </div>
    );

}

export default PriceList;