import React, {  useState, useEffect, useRef } from 'react';
import './TopMenu.css'; // We will create this CSS file next for styling
import logo from '../images/logo.png'
import { FaFacebook} from 'react-icons/fa'; // Import Facebook icon from React Icons
import { HiOutlineBars3BottomLeft, HiBars3} from "react-icons/hi2";


const TopMenu = ({ scrollToRef, refs, activeSection }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(); // Create a ref for the menu


    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false); // Close the menu
            }
        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef, setIsMenuOpen]);

    const handleMenuItemClick = (sectionRef) => {
        scrollToRef(sectionRef); // Scroll to the section
        setIsMenuOpen(false); // Close the menu
    };

    return (
        <nav className={`topMenu`} ref={menuRef}>
            <div className="menuLogo">
                <img src={logo} alt="Logo"/>
            </div>
            <button className="burger" onClick={toggleMenu}>
                {isMenuOpen ? <HiBars3 /> : <HiOutlineBars3BottomLeft />}

            </button>
            <ul className={isMenuOpen ? "navLinks showMenu" : "navLinks"}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <li className={activeSection === 'home' ? 'active' : ''}><a onClick={() => handleMenuItemClick(refs.homeRef)}>Strona Główna</a></li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <li className={activeSection === 'about' ? 'active' : ''}><a onClick={() => handleMenuItemClick(refs.aboutRef)}>O nas</a></li>
                {/*<li className={activeSection === 'portfolio' ? 'active' : ''}><a onClick={() => handleMenuItemClick(refs.portfolioRef)}>Portfolio</a></li>*/}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <li className={activeSection === 'offer' ? 'active' : ''}><a onClick={() => handleMenuItemClick(refs.offerRef)}>Oferta</a></li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <li className={activeSection === 'priceList' ? 'active' : ''}><a onClick={() => handleMenuItemClick(refs.priceListRef)}>Cennik</a></li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <li className={activeSection === 'fotoOnline' ? 'active' : ''}><a onClick={() => handleMenuItemClick(refs.fotoOnlineRef)}>Zrób zdjęcia Online</a></li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <li className={activeSection === 'contact' ? 'active' : ''}><a onClick={() => handleMenuItemClick(refs.contactRef)}>Kontakt</a></li>
                <li className="facebook-icon"><a href="https://www.facebook.com/PiatkowscyFoto" target="_blank"
                                                 rel="noopener noreferrer">
                    <FaFacebook/>
                </a></li>

            </ul>
        </nav>
    );
};

export default TopMenu;
